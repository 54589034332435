.wp-admin {
    [data-ks-block] {
        border: 1px solid black !important;
    }
}

html :where(.wp-block) {
    max-width: 96% !important;
    
    .wp-block {
        max-width: calc(100% - 4px) !important;
    }
}