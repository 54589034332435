form {
    --label-margin: 15px;
    --input-height: 60px;
    --input-inline-padding: 20px;
    --form-max-width: 780px;


    .form_row {
        label {
            display: block;
            margin-bottom: var(--label-margin);
        }

        input[type="text"], input[type="email"] {
            border: 1px solid var(--green500);
            color: var(--white500);
            background-color: transparent;
            width: 100%;
            min-height: var(--input-height);
            padding-inline: var(--input-inline-padding);
            max-width: var(--form-max-width);

            &::placeholder {
                color: var(--grey500);
            }

            &:focus, &:focus-within {
                outline: none;
                box-shadow: 0 0 15px 5px rgb(from var(--green500) r g b / 0.5);
            }
        } 
    }
}