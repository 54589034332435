@use "../helpers/mixins";
@use "../helpers/variables";

* {
  box-sizing: border-box;
  &::before,
  &::after {
    @extend *;
    speak: none;
  }
}
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: 'area-normal';
  font-weight: 600;
}
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--white20);
  container-type: inline-size;
  container-name: body;
  background-color: var(--black500);
  color: var(--white500);
  min-height: 100dvh;
  position: relative;
  isolation: isolate;
  overflow-x: clip;
//   overflow-y: clip;
}
main {
  flex-grow: 1;

  &:not(.full) {
      padding-block: var(--main-padding-block);
  }
}
a {
  color: inherit;
  text-decoration: none;
  transition: all;
  transition-duration: var(--trans-duration);
  transition-timing-function: var(--trans-timing);
}
a:hover {
  color: var(--green500);
  text-decoration: none;
}
h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-weight: 600;
}
b,
strong {
  font-weight: 700;
}
em {
  color: inherit;
}
// Set inherited font family
input,
button,
textarea,
select,
option {
  font-family: inherit;
  &::placeholder {
    color: inherit;
  }
}
button,
select,
input[type="submit"] {
  color: inherit;
  cursor: pointer;
  @include mixins.hover {
    cursor: pointer;
  }
}
textarea {
  overflow: auto;
}
[type="checkbox"] {
  cursor: pointer;
}
img {
  max-height: inherit;
  max-width: 100%;

  // Prevents img without src to appear
  &:not([src]) {
    visibility: hidden;
  }
  //MS Edge fix for lazyload
  &[data-src],
  &[data-srcset] {
    display: block;
    min-height: 1px;
  }
}
.readers__only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

.spacer_large {
    height: var(--spacer-large);
}