.socials {
    --socials-gap: clamp(10px, 3vw, 32px);
    color: var(--green500);
    display: flex;
    flex-wrap: wrap;
    gap: var(--socials-gap);
    align-items: center;
    align-content: center;
}

body:has(nav.active) {
    overflow-y: clip;
}
header.content_grid {
    align-items: center;
    align-content: center;
    height: calc( 2 * var(--header-block-padding) + var(--header-logo-height) );

    .header_logo {
        grid-column: content-start / c3-end;
        height: var(--header-logo-height);
        max-width: 290px;

        @container body (max-width:640px) {
            max-width: unset;
        }
    }
    nav {
        grid-column: c4-start / c10-end;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        gap: clamp(10px, 3vw, var(--spacer-medium));

        #close_mobile {
            display: none;
            position: fixed;
            top:var(--header-block-padding);
            right: var(--padding-inline);
            bottom: auto;
            left: auto;
            z-index: 11;
            background-color: transparent;
            border: 1px solid var(--grey500);
            width: 38px;
            aspect-ratio: 1;
            place-content: center;
            place-items: center;
        }

        @container body (max-width:960px) {
            position: fixed;
            z-index: 99;
            inset:0;
            width: 100dvw;
            height: 100dvh;
            opacity: 1;
            background-color: var(--black500);
            flex-direction: column;
            place-content: center;
            place-items: center;
            overflow: hidden;
            transition-property: height opacity;
            transition-duration: var(--trans-duration);
            transition-timing-function: var(--trans-timing);
            gap: 30px;

            &:not(.active) {
                height: 0dvh;
                opacity: 0;

                #close_mobile {
                    display: none;
                }
            }

            #close_mobile {
                display: flex;

                svg {
                    width: 24px;
                }
            }
        }
    }
    .socials {
        grid-column: c11-start / full-end;
        padding-right: var(--socials-gap);
        justify-content: end;

        @container body (max-width:960px) {
            &.desktop {
                display: none;
            }
        }
        @container body (min-width:961px) {
            &.mobile {
                display: none;
            }
        }
    }
}

#mobile_toggle {
    background-color: transparent;
    border: 1px solid var(--grey500);
    width: 38px;
    aspect-ratio: 1;
    place-content: center;
    place-items: center;
    display: none;
    position: fixed;
    top:var(--header-block-padding);
    right: var(--padding-inline);
    bottom: auto;
    left: auto;
    z-index: 8;

    @container body (max-width:960px) {
        display: flex;
    }

    svg {
        width: 18px;
    }
}

.uk-slideshow-items {
    aspect-ratio: 16/9;

    @container body (max-width: 960px) {
        aspect-ratio: 1;
    }
    @container body (max-width: 640px) {
        aspect-ratio: unset;
        height: 70dvh;
    }
}