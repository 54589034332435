:root {
  --header-logo-height: 75px;
  --header-block-padding: 50px;

  --padding-inline: 40px;
  --main-padding-block: 60px;

  --black300: #141414;
  --black500: #000;
  --black800: #444653;

  --white500: #ffffff;
  --green500: #00ff4c;
  --blue500: #0000ff;
  --grey500: #444653;

  --gradient-deg: -90deg;
  --blue-gradient: linear-gradient(var(--gradient-deg), var(--blue500) 0%, var(--blue500) 90%);
  --green-gradient: linear-gradient(var(--gradient-deg), var(--green500) 0%, var(--green500) 90%);
  --black-gradient: linear-gradient(var(--gradient-deg), var(--black500) 0%, var(--black500) 90%);

  --trans-duration: 0.3s;
  --trans-duration-long: 0.5s;
  --trans-duration-xlong: 0.75s;
  --trans-timing: ease;

  --spacer-xsmall: 10px;
  --spacer-small: 25px;
  --spacer-medium-small: 35px;
  --spacer-medium: 55px;
  --spacer-large: 85px;
  --spacer-xlarge: 115px;
  --spacer-xxlarge: 180px;
  --spacer-xxxlarge: 250px;

  --flex-group-gap: 25px;
  --btn-flex-gap: 10px;

  @media (max-width: 640px) {
    --header-logo-height: 50px;
  }
  @container body (max-width: 640px) {
    .content_grid {
      --padding-inline: 20px;
    }
  }
}
h1,
.h1 {
  font-size: clamp(48px, 7vw, 120px);
  line-height: 1.2;
}
h2,
.h2 {
  font-size: clamp(32px, 2.5vw, 48px);
  line-height: 1.2;
}
h3,
.h3 {
  font-size: clamp(20px, 2.5vw, 36px);
  line-height: 1.2;
  padding-bottom: var(--spacer-small);
}
h4,
.h4 {
  font-size: clamp(18px, 2.3vw, 30px);
  line-height: 1.2;
}
h5,
.h5 {
}
h6,
.h6 {
}
.text_xsmall {
  font-size: 14px;
  line-height: 1.2;
}
.text_medium {
  font-size: clamp(16px, 2.5vw, 24px);
  line-height: 1.2;
}
.text_xxlarge {
  font-size: clamp(26px, 5vw, 96px);
  line-height: 1.2;
}

.flex_group {
  display: flex;
  flex-wrap: wrap;
  gap: var(--flex-group-gap);

  .btn {
    @container body (max-width: 640px) {
      min-width: 120px;
    }
  }

  &.align_center {
    align-items: center;
    align-content: center;
  }

  &.justify_between {
    justify-content: space-between;
  }
}
