.intro {
    --panel-padding-block: clamp(50px, 5vw, 60px);
    --panel-padding-inline: clamp(28px, 5vw, 55px);
    --panel-font-size: clamp(16px, 2.25vw, 36px);
    --logo-height: 75px;
    --penel-els-row-gap: 50px;
    --gradient-stop: 80%;

    grid-template-rows: 1fr auto auto;

    min-height: 100dvh;

    p {
        margin: 0;
        font-size: var(--panel-font-size);
        line-height: 1.2;
    }
    .panel {
        color: var(--white500);
        grid-column: span 4;
        min-height: 100dvh;
        background-image: var(--bg-image);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        isolation: isolate;
        padding-inline: var(--panel-padding-inline);
        padding-block: var(--panel-padding-block);
        align-content: start;
        display: grid;
        grid-row: span 3;
        grid-template-rows: subgrid;
        row-gap: var(--penel-els-row-gap);
        overflow: clip;

        @container body (max-width: 960px) {
            grid-column: span 6;
        }

        &:hover {
            .bg_image {
                scale: 1.3;
            }
        }

        .bg_image {
            position: absolute;
            z-index: 1;
            pointer-events: none;
            height: var(--gradient-stop);
            width: 100%;
            object-fit: cover;
            transition-property: scale;
            transition-duration: var(--trans-duration-long);
            transition-timing-function: var(--trans-timing);
        }

        .logo {
            height: var(--logo-height);
        }

        &:before {
            content:'';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            z-index: 2;
        }
        * {
            position: relative;
            z-index: 3;
        }

        &.green {
            background-color: var(--green500);

            &::before {
                background: rgb(0,0,0);
                background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,255,76,1) var(--gradient-stop));
                background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,255,76,1) var(--gradient-stop));
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,255,76,1) var(--gradient-stop));
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00ff4c",GradientType=1);
            }
        }
        &.blue {
            background-color:  var(--blue500);

            &::before {
                background: rgb(0,0,0);
                background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,255,1) var(--gradient-stop));
                background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,255,1) var(--gradient-stop));
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,255,1) var(--gradient-stop));
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#0000ff",GradientType=1);
            }
        }
        &.black {
            background-color:  var(--black500);

            &::before {
                background: rgb(0,0,0);
                background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) var(--gradient-stop));
                background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) var(--gradient-stop));
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) var(--gradient-stop));
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
            }
        }
    }
}