footer.content_grid {
    --footer-height: 105px;
    --terms-els-gap: 40px;
    
    @container body (max-width:640px) {
        --terms-els-gap: 10px;
    }

    min-height: var(--footer-height);
    align-items: center;
    align-content: center;
    position: relative;
    font-weight: 400;

    & > hr {
        width: 100%;
        margin: 0;
        border-color: var(--green500);
        border-width: 1px;
        border-bottom:0;

        &.top_border {
            position: absolute;
            inset:0;
            bottom: auto;
            margin-inline: auto;
        }
    }

    .footer_logo {
        grid-column: c1-start / c1-end;
        
        @container body (max-width:640px) {
            grid-column: c1-start / c6-end;
        }
        @container body (max-width:320px) {
            grid-column: content;
            padding-block: 15px;
        }
    }

    .terms_menu {
        grid-column: c3-start / content-end;
        display: flex;
        flex-wrap: wrap;
        gap: var(--terms-els-gap);
        justify-content: end;
        
        @container body (max-width:640px) {
            grid-column: c7-start / c12-end;
            justify-content: start;
            padding-block: 15px;
            font-size: 11px;
            letter-spacing: -0.5px;
        }
        @container body (max-width:320px) {
            grid-column: content;
        }
    }

    &.full_footer {
        align-items: start;
        align-content: start;
        padding-top: var(--spacer-medium);
    }
    
    .footer_widget {
        grid-column: span 3;
        display: grid;
        gap: var(--spacer-xsmall);
        padding-right: var(--spacer-medium);
        padding-bottom: var(--spacer-medium);
        
        @container body (max-width: 960px) {
            padding-right: 0;
            padding-left: var(--spacer-xsmall);
            grid-column: span 2;
        }
        @container body (max-width: 640px) {
            padding-left: 0;
            grid-column: content !important;
        }
        
        .title {
            font-size: clamp(16px, 3vw, 24px);
            margin-bottom: var(--spacer-xsmall);
            text-align: right;
        }
        ul {
            display: grid;
            justify-content: end;
            justify-items: end;
            gap: var(--spacer-xsmall);
            list-style: none;
            padding: 0;
            margin: 0;
        }
    }
    .footer_1 {
        grid-column-start: content-start;
        grid-column-end: c2-end;
        
        @container body (max-width: 1200px) {
            grid-column-end: c3-end;
        }
        @container body (max-width: 960px) {
            grid-column-end: c12-end;
        }

        & > * {
            grid-column: 1 / -1;
        }
    }
    .footer_2 {
        @container body (max-width: 960px) {
            grid-column: c1-start / c4-end;
        }
    }
    .footer_4 {
        text-align: right;

        @container body (max-width: 960px) {
            // grid-column-start: c1-start;
            // grid-column-end: c12-end;
        }
    }

    .copy {
        position: relative;
        padding-block: var(--spacer-small);

        .terms_menu {
            grid-column: c1-start / c6-end;
            text-align: left;
            justify-content: start;
            justify-items: start;

            @container body (max-width:640px) {
                grid-column: content;
            }
        }

        .made_by {
            grid-column: c7-start / c12-end;
            text-align: right;

            @container body (max-width:640px) {
                grid-column: content;
                justify-content: start;
                padding-block: 15px;
                font-size: 11px;
                letter-spacing: -0.5px;
            }
        }
        
        & > hr {
            width: 100%;
            margin: 0;
            border-color: var(--green500);
            border-width: 1px;
            border-bottom:0;
    
            &.top_border {
                position: absolute;
                inset:0;
                bottom: auto;
                margin-inline: auto;
            }
        }
    }
}