@use "../helpers/mixins";

.content_grid {
  --content-max-width: 1580px;
  --breakout-max-width: 1580px;
  --breakout-size: calc((var(--breakout-max-width) - var(--content-max-width)) / 2);
  --column-width: min(var(--content-max-width) / 12, calc((100% - (var(--padding-inline) * 2)) / 12));
  --column-width-960: min(var(--content-max-width) / 6, calc((100% - (var(--padding-inline) * 2)) / 6));
  --column-width-640: min(var(--content-max-width) / 2, calc((100% - (var(--padding-inline) * 2)) / 2));
  
  &.full {
      --content-max-width: 100dvw;
      --breakout-max-width: 100dvw;
      --padding-inline: 0px;

      grid-template-columns:
      [content-start extend-content-right-start c1-start]
      var(--column-width)
      [c1-end c2-start]
      var(--column-width)
      [c2-end c3-start]
      var(--column-width)
      [c3-end c4-start]
      var(--column-width)
      [c4-end c5-start]
      var(--column-width)
      [c5-end c6-start]
      var(--column-width)
      [c6-end c7-start]
      var(--column-width)
      [c7-end c8-start]
      var(--column-width)
      [c8-end c9-start]
      var(--column-width)
      [c9-end c10-start]
      var(--column-width)
      [c10-end c11-start]
      var(--column-width)
      [c11-end c12-start]
      var(--column-width)
      [content-end extend-content-left-end c12-end]
  }

  overflow-x: clip;
  display: grid;
  align-items: start;
  align-content: start;
  grid-template-columns:
    [full-start extend-content-left-start]
    minmax(var(--padding-inline), 1fr)
    [breakout-start]
    minmax(0, var(--breakout-size))
    [content-start extend-content-right-start c1-start]
    var(--column-width)
    [c1-end c2-start]
    var(--column-width)
    [c2-end c3-start]
    var(--column-width)
    [c3-end c4-start]
    var(--column-width)
    [c4-end c5-start]
    var(--column-width)
    [c5-end c6-start]
    var(--column-width)
    [c6-end c7-start]
    var(--column-width)
    [c7-end c8-start]
    var(--column-width)
    [c8-end c9-start]
    var(--column-width)
    [c9-end c10-start]
    var(--column-width)
    [c10-end c11-start]
    var(--column-width)
    [c11-end c12-start]
    var(--column-width)
    [content-end extend-content-left-end c12-end]
    minmax(0, var(--breakout-size))
    [breakout-end]
    minmax(var(--padding-inline), 1fr)
    [full-end extend-content-right-end];

  @container body (max-width: 960px) {
    grid-template-columns:
      [full-start extend-content-left-start]
      minmax(var(--padding-inline), 1fr)
      [content-start breakout-start extend-content-right-start c1-start c2-start]
      var(--column-width-960)
      [c1-end c2-end c3-start c4-start]
      var(--column-width-960)
      [c3-end c4-end c5-start c6-start]
      var(--column-width-960)
      [c5-end c6-end c7-start c8-start]
      var(--column-width-960)
      [c7-end c8-end c9-start c10-start]
      var(--column-width-960)
      [c9-end c10-end c11-start c12-start]
      var(--column-width-960)
      [content-end breakout-end extend-content-left-end c11-end c12-end]
      minmax(var(--padding-inline), 1fr)
      [full-end extend-content-right-end];

      &.full {
        --content-max-width: 100dvw;
        --breakout-max-width: 100dvw;
        --padding-inline: 0px;

        grid-template-columns:
        [content-start breakout-start extend-content-right-start c1-start c2-start]
        var(--column-width-960)
        [c1-end c2-end c3-start c4-start]
        var(--column-width-960)
        [c3-end c4-end c5-start c6-start]
        var(--column-width-960)
        [c5-end c6-end c7-start c8-start]
        var(--column-width-960)
        [c7-end c8-end c9-start c10-start]
        var(--column-width-960)
        [c9-end c10-end c11-start c12-start]
        var(--column-width-960)
        [content-end breakout-end extend-content-left-end c11-end c12-end]
    }
  }

  @container body (max-width: 640px) {

    grid-template-columns:
      [full-start extend-content-left-start]
      minmax(var(--padding-inline), 1fr)
      [content-start breakout-start extend-content-right-start c1-start c2-start c3-start c4-start c5-sart c6-start]
      var(--column-width-640)
      [c1-end c2-end c3-end c4-end c5-end c6-end c7-start c8-start c9-start c10-start c11-start c12-start]
      var(--column-width-640)
      [content-end breakout-end extend-content-left-end c7-end c8-end c9-end c10-end c11-end c12-end]
      minmax(var(--padding-inline), 1fr)
      [full-end extend-content-right-end];

      &.full {
        --padding-inline: 0px;
        --content-max-width: 100dvw;
        --breakout-max-width: 100dvw;
        --padding-inline: 0px;
    
        grid-template-columns:
        [content-start breakout-start extend-content-right-start c1-start c2-start c3-start c4-start c5-sart c6-start]
        var(--column-width-640)
        [c1-end c2-end c3-end c4-end c5-end c6-end c7-start c8-start c9-start c10-start c11-start c12-start]
        var(--column-width-640)
        [content-end breakout-end extend-content-left-end c7-end c8-end c9-end c10-end c11-end c12-end]
    }
  }
}

.content_grid > *:not(.full_width, .extend_content_left, .extend_content_right, [class*="col_"]),
.full_width > *:not(.full_width, .extend_content_left, .extend_content_right, [class*="col_"]) {
  grid-column-start: content-start;
  grid-column-end: content-end;
}

.full_width {
  grid-column: full;

  &:not(.extend_contents) {
    display: grid;
    grid-template-columns: inherit;
  }
}
.extend_content_left {
  grid-column: extend-content-left;
}
.extend_content_right {
  grid-column: extend-content-right;
}

.col_1_w_1 {
  grid-column-start: c1-start;
  grid-column-end: c1-end;
}
.col_1_w_2 {
  grid-column-start: c1-start;
  grid-column-end: c2-end;
}
.col_1_w_3 {
  grid-column-start: c1-start;
  grid-column-end: c3-end;
}
.col_1_w_4 {
  grid-column-start: c1-start;
  grid-column-end: c4-end;
}
.col_1_w_5 {
  grid-column-start: c1-start;
  grid-column-end: c5-end;
}
.col_1_w_6 {
  grid-column-start: c1-start;
  grid-column-end: c6-end;
}
.col_1_w_7 {
  grid-column-start: c1-start;
  grid-column-end: c7-end;
}
.col_1_w_8 {
  grid-column-start: c1-start;
  grid-column-end: c8-end;
}
.col_1_w_9 {
  grid-column-start: c1-start;
  grid-column-end: c9-end;
}
.col_1_w_10 {
  grid-column-start: c1-start;
  grid-column-end: c10-end;
}
.col_1_w_11 {
  grid-column-start: c1-start;
  grid-column-end: c11-end;
}
.col_1_w_12 {
  grid-column-start: c1-start;
  grid-column-end: c12-end;
}

.col_2_w_1 {
  grid-column-start: c2-start;
  grid-column-end: c2-end;
}
.col_2_w_2 {
  grid-column-start: c2-start;
  grid-column-end: c3-end;
}
.col_2_w_3 {
  grid-column-start: c2-start;
  grid-column-end: c4-end;
}
.col_2_w_4 {
  grid-column-start: c2-start;
  grid-column-end: c5-end;
}
.col_2_w_5 {
  grid-column-start: c2-start;
  grid-column-end: c6-end;
}
.col_2_w_6 {
  grid-column-start: c2-start;
  grid-column-end: c7-end;
}
.col_2_w_7 {
  grid-column-start: c2-start;
  grid-column-end: c8-end;
}
.col_2_w_8 {
  grid-column-start: c2-start;
  grid-column-end: c9-end;
}
.col_2_w_9 {
  grid-column-start: c2-start;
  grid-column-end: c10-end;
}
.col_2_w_10 {
  grid-column-start: c2-start;
  grid-column-end: c11-end;
}
.col_2_w_11 {
  grid-column-start: c2-start;
  grid-column-end: c12-end;
}

.col_3_w_1 {
  grid-column-start: c3-start;
  grid-column-end: c3-end;
}
.col_3_w_2 {
  grid-column-start: c3-start;
  grid-column-end: c4-end;
}
.col_3_w_3 {
  grid-column-start: c3-start;
  grid-column-end: c5-end;
}
.col_3_w_4 {
  grid-column-start: c3-start;
  grid-column-end: c6-end;
}
.col_3_w_5 {
  grid-column-start: c3-start;
  grid-column-end: c7-end;
}
.col_3_w_6 {
  grid-column-start: c3-start;
  grid-column-end: c8-end;
}
.col_3_w_7 {
  grid-column-start: c3-start;
  grid-column-end: c9-end;
}
.col_3_w_8 {
  grid-column-start: c3-start;
  grid-column-end: c10-end;
}
.col_3_w_9 {
  grid-column-start: c3-start;
  grid-column-end: c11-end;
}
.col_3_w_10 {
  grid-column-start: c3-start;
  grid-column-end: c12-end;
}

.col_4_w_1 {
  grid-column-start: c4-start;
  grid-column-end: c4-end;
}
.col_4_w_2 {
  grid-column-start: c4-start;
  grid-column-end: c5-end;
}
.col_4_w_3 {
  grid-column-start: c4-start;
  grid-column-end: c6-end;
}
.col_4_w_4 {
  grid-column-start: c4-start;
  grid-column-end: c7-end;
}
.col_4_w_5 {
  grid-column-start: c4-start;
  grid-column-end: c8-end;
}
.col_4_w_6 {
  grid-column-start: c4-start;
  grid-column-end: c9-end;
}
.col_4_w_7 {
  grid-column-start: c4-start;
  grid-column-end: c10-end;
}
.col_4_w_8 {
  grid-column-start: c4-start;
  grid-column-end: c11-end;
}
.col_4_w_9 {
  grid-column-start: c4-start;
  grid-column-end: c12-end;
}

.col_5_w_1 {
  grid-column-start: c5-start;
  grid-column-end: c5-end;
}
.col_5_w_2 {
  grid-column-start: c5-start;
  grid-column-end: c6-end;
}
.col_5_w_3 {
  grid-column-start: c5-start;
  grid-column-end: c7-end;
}
.col_5_w_4 {
  grid-column-start: c5-start;
  grid-column-end: c8-end;
}
.col_5_w_5 {
  grid-column-start: c5-start;
  grid-column-end: c9-end;
}
.col_5_w_6 {
  grid-column-start: c5-start;
  grid-column-end: c10-end;
}
.col_5_w_7 {
  grid-column-start: c5-start;
  grid-column-end: c11-end;
}
.col_5_w_8 {
  grid-column-start: c5-start;
  grid-column-end: c12-end;
}

.col_6_w_1 {
  grid-column-start: c6-start;
  grid-column-end: c6-end;
}
.col_6_w_2 {
  grid-column-start: c6-start;
  grid-column-end: c7-end;
}
.col_6_w_3 {
  grid-column-start: c6-start;
  grid-column-end: c8-end;
}
.col_6_w_4 {
  grid-column-start: c6-start;
  grid-column-end: c9-end;
}
.col_6_w_5 {
  grid-column-start: c6-start;
  grid-column-end: c10-end;
}
.col_6_w_6 {
  grid-column-start: c6-start;
  grid-column-end: c11-end;
}
.col_6_w_7 {
  grid-column-start: c6-start;
  grid-column-end: c12-end;
}

.col_7_w_1 {
  grid-column-start: c7-start;
  grid-column-end: c7-end;
}
.col_7_w_2 {
  grid-column-start: c7-start;
  grid-column-end: c8-end;
}
.col_7_w_3 {
  grid-column-start: c7-start;
  grid-column-end: c9-end;
}
.col_7_w_4 {
  grid-column-start: c7-start;
  grid-column-end: c10-end;
}
.col_7_w_5 {
  grid-column-start: c7-start;
  grid-column-end: c11-end;
}
.col_7_w_6 {
  grid-column-start: c7-start;
  grid-column-end: c12-end;
}

.col_8_w_1 {
  grid-column-start: c8-start;
  grid-column-end: c8-end;
}
.col_8_w_2 {
  grid-column-start: c8-start;
  grid-column-end: c9-end;
}
.col_8_w_3 {
  grid-column-start: c8-start;
  grid-column-end: c10-end;
}
.col_8_w_4 {
  grid-column-start: c8-start;
  grid-column-end: c11-end;
}
.col_8_w_5 {
  grid-column-start: c8-start;
  grid-column-end: c12-end;
}

.col_9_w_1 {
  grid-column-start: c9-start;
  grid-column-end: c9-end;
}
.col_9_w_2 {
  grid-column-start: c9-start;
  grid-column-end: c10-end;
}
.col_9_w_3 {
  grid-column-start: c9-start;
  grid-column-end: c11-end;
}
.col_9_w_4 {
  grid-column-start: c9-start;
  grid-column-end: c12-end;
}

.col_10_w_1 {
  grid-column-start: c10-start;
  grid-column-end: c10-end;
}
.col_10_w_2 {
  grid-column-start: c10-start;
  grid-column-end: c11-end;
}
.col_10_w_3 {
  grid-column-start: c10-start;
  grid-column-end: c12-end;
}

.col_11_w_1 {
  grid-column-start: c11-start;
  grid-column-end: c11-end;
}
.col_11_w_2 {
  grid-column-start: c11-start;
  grid-column-end: c12-end;
}

.col_12_w_1 {
  grid-column-start: c12-start;
  grid-column-end: c12-end;
}

.col_full_start_1 {
  grid-column-start: full-start;
  grid-column-end: c1-end;
}
.col_full_start_2 {
  grid-column-start: full-start;
  grid-column-end: c2-end;
}
.col_full_start_3 {
  grid-column-start: full-start;
  grid-column-end: c3-end;
}
.col_full_start_4 {
  grid-column-start: full-start;
  grid-column-end: c4-end;
}
.col_full_start_5 {
  grid-column-start: full-start;
  grid-column-end: c5-end;
}
.col_full_start_6 {
  grid-column-start: full-start;
  grid-column-end: c6-end;
}
.col_full_start_7 {
  grid-column-start: full-start;
  grid-column-end: c7-end;
}
.col_full_start_8 {
  grid-column-start: full-start;
  grid-column-end: c8-end;
}
.col_full_start_9 {
  grid-column-start: full-start;
  grid-column-end: c9-end;
}
.col_full_start_10 {
  grid-column-start: full-start;
  grid-column-end: c10-end;
}
.col_full_start_11 {
  grid-column-start: full-start;
  grid-column-end: c11-end;
}
.col_full_start_12 {
  grid-column-start: full-start;
  grid-column-end: c12-end;
}
.col_1_full_end {
  grid-column-start: c1-start;
  grid-column-end: full-end;
}
.col_2_full_end {
  grid-column-start: c2-start;
  grid-column-end: full-end;
}
.col_3_full_end {
  grid-column-start: c3-start;
  grid-column-end: full-end;
}
.col_4_full_end {
  grid-column-start: c4-start;
  grid-column-end: full-end;
}
.col_5_full_end {
  grid-column-start: c5-start;
  grid-column-end: full-end;
}
.col_6_full_end {
  grid-column-start: c6-start;
  grid-column-end: full-end;
}
.col_7_full_end {
  grid-column-start: c7-start;
  grid-column-end: full-end;
}
.col_8_full_end {
  grid-column-start: c8-start;
  grid-column-end: full-end;
}
.col_9_full_end {
  grid-column-start: c9-start;
  grid-column-end: full-end;
}
.col_10_full_end {
  grid-column-start: c10-start;
  grid-column-end: full-end;
}
.col_11_full_end {
  grid-column-start: c11-start;
  grid-column-end: full-end;
}
.col_12_full_end {
  grid-column-start: c12-start;
  grid-column-end: full-end;
}
