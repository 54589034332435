.btn {
  --btn-min-width: 196px;
  --btn-min-height: 42px;

  text-decoration: none;
  color: var(--white500);
  border: 1px solid var(--white500);
  border-radius: 1000px;
  min-width: var(--btn-min-width);
  width: max-content;
  min-height: var(--btn-min-height);
  display: flex;
  place-content: center;
  place-items: center;
  gap: var(--btn-flex-gap);
  padding-inline: calc(var(--btn-flex-gap) * 2);
  padding-bottom: 2px;
  text-align: center;
  justify-content: center;
  justify-items: center;
  transition-duration: var(--trans-duration);
  transition-timing-function: var(--trans-timing);

  &.fit_content {
    --btn-min-width: fit-content;
    padding-inline: var(--spacer-medium-small);
  }

  &:is(button) {
    border-color: transparent;
  }

  &:hover {
    border-color: var(--green500);
    color: var(--green500);
  }

  &.green {
    background-color: var(--green500);
    color: var(--black500);
    border-color: var(--green500);

    &:hover {
      background-color: transparent;
      color: var(--green500);
    }
  }
  &.disabled,
  &[disabled] {
    background-color: transparent;
    border-color: var(--grey500);
    color: var(--grey500);

    &:hover {
      background-color: var(--grey500);
      color: var(--white500);
    }
  }

  &.blue_hover {
    &:hover {
      border-color: var(--blue500);
      color: var(--blue500);
    }
  }
  &.black_hover {
    &:hover {
      border-color: var(--black500);
      color: var(--black500);
    }
  }

  @container body (max-width: 320px) {
    --btn-min-width: unset;
    width: 100%;
  }
}
