@use "../helpers/mixins";
@use "../helpers/variables";

.custom_checkbox {
  --checkbox-padding-block: 30px;
  --checkbox-flex-gap: 15px;
  --checkbox-size: 20px;
  --checkmark-top-offset: -1px;
  --checkmark-left-offset: 0.5px;

  font-size: clamp(12px, 2vw, 16px);
  display: flex;
  gap: var(--checkbox-flex-gap);
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.3;
  margin-block: var(--checkbox-padding-block);

  a {
    color: var(--green500);
    text-decoration: underline;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    height: var(--checkbox-size);
    min-width: var(--checkbox-size);
    background-color: transparent;
    border: 1px solid var(--grey500);
    border-radius: 4px;
    display: flex;
    place-content: center;
    place-items: center;
    order: -1;

    &:after {
      content: "";
      display: none;
      width: 6px;
      height: 10px;
      translate: var(--checkmark-left-offset) var(--checkmark-top-offset);
      border: solid var(--green500);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    background-color: rgba(255, 255, 255, 0.2);
  }
  input:checked ~ .checkmark {
    background-color: transparent;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
}
