// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts: '../assets/fonts/';

.fw_100 {
    font-weight: 100;
}
.fw_200 {
    font-weight: 200;
}
.fw_300 {
    font-weight: 300;
}
.fw_400 {
    font-weight: 400;
}
.fw_500 {
    font-weight: 500;
}
.fw_600 {
    font-weight: 600;
}
.fw_700 {
    font-weight: 700;
}
.fw_800 {
    font-weight: 800;
}
.fw_900 {
    font-weight: 900;
}

.text_center {
    text-align: center;
    justify-content: center;
    justify-items: center;
}