.content_grid.meetup_info {
  padding-top: calc(2 * var(--main-padding-block));
}
.meetup_info_content {
  grid-column: content-start / c7-end;
  margin-bottom: var(--spacer-xxxlarge);

  @container body ( max-width: 640px ) {
    margin-bottom: var(--main-padding-block);
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;
    padding-bottom: var(--spacer-medium);
    text-shadow: 0 0 3px 3px var(--black500);

    @container body (max-width:960px) {
      flex-direction: column;
      gap: 10px;
    }

    .compress {
      @container body (max-width:960px) {
        font-size: clamp(12px, 2vw, 16px);
        color: var(--white500) !important;
        font-weight: 400;

        * {
          color: var(--white500) !important;
        }

        br {
          display: none;
        }
      }
    }
  }
}
.bg_element {
  --bg-offset-right: 26vw;
  --bg-offset-top: -9vw;

  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  translate: var(--bg-offset-right) var(--bg-offset-top);

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  transform-origin: top left;

  @container body (max-width: 960px) {
    scale: 0.6;
    opacity: 0.7;

    --bg-offset-right: 20vw;
    --bg-offset-top: 100px;
  }
  @container body (max-width: 640px) {
    scale: 0.3;

    --bg-offset-right: 15vw;
    --bg-offset-top: 120px;
  }

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }
  svg {
    max-width: unset !important;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: url(#bg_clip);

    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  #bg_colors {
    z-index: 3;
    mix-blend-mode: color-burn;
  }
}
.chip {
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 1000px;
  padding-inline: calc(var(--btn-flex-gap) * 2);
  padding-block: 10px;
  transition-property: color border-color background-color;
  transition-duration: var(--trans-duration-xlong);
  transition-timing-function: var(--trans-timing);

  &.green {
    border: 1px solid var(--green500);
    color: var(--green500);
  }
}
.meetup_page {
  --section-padding-top: 350px;
  --section-padding-top-medium: 150px;
  --section-padding-top-small: 50px;

  header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 11;

    @container body (max-width:960px) {
        position: static;
    }
  }

  .section_title {
    padding-bottom: var(--spacer-xlarge);

    @container body (max-width: 960px) {
      padding-bottom: var(--spacer-medium);
    }
  }
  main.content_grid:has(> .home:first-child) {
    padding-top: 0;
  }
  .home {
    --slide-in-duration: 750ms;
    --slide-in-delay: 200ms;

    .uk-transition-slide-left-medium {
      // transform: translateX(-20px);
    }
    .uk-active {
      .uk-transition-slide-left-medium {
        // transform: translateX(20px);
      }
    }

    .slider_container {
      grid-column-start: c1-start;
      grid-column-end: full-end;

      .slide_item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        align-content: center;
        // background-color: var(--black500);
        min-height: 100dvh;

        & > * {
          grid-column: 1 / -1;
          grid-row: 1 / -1;
        }

        .slider_content_wrapper {
          display: grid;
          height: 100%;

          @container body (max-width: 960px) {
            display: block;
          }
        }

        .slide_content {
          padding-block: var(--spacer-large);
          margin-top: 15dvh;
        }
        .h1 {
          line-height: 1.1;
          margin-bottom: var(--spacer-small);
          transition-duration: var(--slide-in-duration);
          transition-delay: var(--slide-in-delay);
        }
        .desc {
          color: var(--green500);
          margin-bottom: var(--spacer-small);
          transition-duration: var(--slide-in-duration);
          transition-delay: calc(2 * var(--slide-in-delay));
        }
        .annotation {
          transition-duration: var(--slide-in-duration);
          transition-delay: calc(3 * var(--slide-in-delay));
        }
        img {
          align-self: start;
          justify-self: end;
          max-width: 728px;
          width: calc(728px * 100% / 1920px);

          @container body (max-width: 960px) {
            display: none;
          }
        }
      }
    }

    .bg_element {
      img {
        clip-path: url(#bg_clip_slide);
      }
      @container body (min-width: 641px) {
        display: none !important;
      }
    }
  }
  .about_us {
    position: relative;
    padding-top: var(--section-padding-top);

    @container body (max-width:640px) {
      padding-top: var(--spacer-large);
    }

    .bg_element {
      --bg-offset-top: 0;
      grid-column: full;

      @container body (max-width:640px) {
        display: none;
      }
    }
  }
  .place {
    .place_content {
      grid-column-start: c9-start;
      grid-column-end: c12-end;

      translate: 0 150%;

      @container body (max-width:960px) {
        grid-column: content;
        translate: 0 0;
        order: 2;
      }
    }

    .place_image {
      grid-column: full;

      @container body (min-width:961px) {
        &.mobile {
          display: none;
        }
      }
      @container body (max-width:960px) {
        order: 1;

        &.mobile {
          width: 100%;
          padding-top: var(--spacer-xlarge);
          padding-bottom: var(--spacer-large);
        }
        &.desktop {
          display: none;
        }
      }
    }
  }
  .agenda {
    padding-top: var(--section-padding-top);
    background-image: var(--bg-img);
    background-size: cover;
    background-position: center calc(-1 * var(--section-padding-top));
    background-repeat: no-repeat;

    @container body (max-width: 960px) {
      padding-top: var(--spacer-xxlarge);
    }
  }
  .plan_component {
    @container body (max-width:960px) {
      grid-column: full !important;
    }

    .filters {
      .title {
        @container body (max-width: 960px) {
          padding-inline: var(--padding-inline);
        }
      }
      .filters_buttons {
        flex-wrap: nowrap;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
      .btn {
        background-color: transparent;
        border-color: var(--green500);
        color: var(--green500);
        min-width: max-content;
        white-space: nowrap;

        &:first-child {
          @container body (max-width: 960px) {
            margin-left: var(--padding-inline);
          }
        }
        &:last-child {
          @container body (max-width: 960px) {
            margin-right: var(--padding-inline);
          }
        }

        &:hover {
          background-color: var(--green500);
          color: var(--black500);
        }

        &.active {
          background-color: var(--green500);
          color: var(--black500);
        }
      }
    }

    .plan_body {
      border-top: 1px solid var(--green500);
      margin-top: var(--spacer-medium);

      .plan_item {
        display: grid;
        grid-template-columns: 1fr 2fr 3fr 2fr;
        gap: var(--flex-group-gap);
        align-items: center;
        align-content: center;
        border-bottom: 1px solid var(--green500);
        min-height: 150px;
        padding-block: 55px;
        position: relative;
        isolation: isolate;
        color: var(--white500);
        transition-property: color height min-height padding-block;
        transition-duration: var(--trans-duration-xlong);
        transition-timing-function: var(--trans-timing);
        z-index: 3;

        @container body (max-width: 960px) {
          grid-template-columns: 1fr;
          padding-right: var(--padding-inline);

          & > * {
            padding-left: var(--padding-inline);
          }
        }

        &.hidden {
          overflow-y: hidden;
          height: 0;
          min-height: 0;
          padding-block: 0;
          border-bottom: 0;
        }

        &:not(.organizing):before {
          content: "";
          width: 0%;
          height: 100%;
          background-color: var(--green500);
          position: absolute;
          inset: 0;
          z-index: -1;
          transition-property: width;
          transition-duration: var(--trans-duration-xlong);
          transition-timing-function: var(--trans-timing);
        }

        &:hover {
          z-index: 9;

          &:not(.organizing) {
            @container body (min-width: 961px) {
              color: var(--black500);

              .chip.green {
                color: var(--black500);
                border-color: var(--black500);
              }
            }
          }

          @container body (min-width: 961px) {
            &:before {
              width: 100%;
            }
          }
          .image {
            img {
              translate: 0 -50%;

              @container body (max-width: 960px) {
                translate: 0 0;
                opacity: 0.5;
              }
            }
          }
        }

        &.organizing {
          background-color: var(--black300);
          z-index: 1;
        }

        .title {
          .h4 {
            @container body (max-width: 960px) {
              font-size: 30px;
            }
          }
        }
        .desc {
          @container body (max-width: 960px) {
            font-size: 18px;
            color: var(--green500);
          }
        }

        .time {
          padding-left: var(--padding-inline);
        }

        .image {
          position: relative;
          overflow-x: clip;

          @container body (max-width: 960px) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }

          img {
            pointer-events: none;
            position: absolute;
            top: 0;
            translate: -110% -50%;
            transition-property: translate;
            transition-duration: var(--trans-duration-xlong);
            transition-timing-function: var(--trans-timing);
            object-fit: cover;

            @container body (max-width: 960px) {
              z-index: -1;
              opacity: 0;
              translate: -110% 0;
              right: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .speakers {
    padding-top: var(--section-padding-top-medium);

    .speakers_grid {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: 1fr auto;
      gap: var(--flex-group-gap);
      row-gap: var(--spacer-large);

      @container body (max-width:960px) {
        margin-inline: calc(-1 * var(--padding-inline));
        display: flex;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .persona {
        display: grid;
        grid-row: span 2;
        grid-template-rows: subgrid;
        row-gap: var(--flex-group-gap);
        padding-bottom: var(--flex-group-gap);
        border-bottom: 1px solid var(--white500);
        position: relative;
        isolation: isolate;
        transition-property: color;
        transition-duration: var(--trans-duration-xlong);
        transition-timing-function: var(--trans-timing);

        @container body (max-width: 960px) {
          min-width: 80dvw;
        }

        &:after {
          content: "";
          width: 0%;
          height: 100%;
          background-color: var(--green500);
          position: absolute;
          inset: 0;
          z-index: 3;
          mix-blend-mode: multiply;
          transition-property: width;
          transition-duration: var(--trans-duration-xlong);
          transition-timing-function: var(--trans-timing);
        }

        &:hover {
          color: var(--black500);

          &:after {
            width: 100%;
          }
        }

        & > div {
          position: relative;
          z-index: 5;
          padding-inline: var(--flex-group-gap);
        }

        .h4 {
          padding-bottom: var(--spacer-xsmall);
        }
        img {
          aspect-ratio: 1;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .partners {
    padding-top: var(--section-padding-top);

    @container body (max-width:960px) {
      padding-top: var(--spacer-xxlarge);
    }

    .partners_body {
      .partners_row {
        border-top: 1px solid var(--black800);
        color: var(--black800);
        display: grid;
        grid-template-rows: 1fr auto 1fr;
        min-height: 250px;

        .title {
          padding-top: var(--spacer-small);
          padding-bottom: var(--spacer-small);
        }
        .flex {
          display: flex;
          gap: clamp(20px, 8vw, 150px);
          align-items: center;
          align-content: center;
          justify-content: center;
          justify-items: center;

          @container body (max-width: 370px) {
            flex-wrap: wrap;
            gap: 40px;
            padding-inline: var(--padding-inline);

            & > * {
              flex: 0 1 100%;
            }
          }
        }

        img {
          max-height: 115px;
        }
      }
    }
  }
  .extra {
    background-image: var(--bg-img);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    align-content: center;
    padding-top: var(--section-padding-top-small);

    .img {
      grid-column: full-start / c6-end;
      position: relative;
      z-index: 0;

      @container body (max-width:640px) {
        grid-column: full;
        margin-bottom: calc(-1 * var(--spacer-xlarge));
      }

      img {
        width: 100%;
      }
    }
    .content {
      grid-column: c7-start / c12-end;
      z-index: 3;

      @container body (max-width:640px) {
        grid-column: content;
      }
    }

    #lock {
      --animation-duration: 2000ms;
      max-width: 38%;
      width: 100%;
      position: absolute;
      inset-inline: 0;
      top: 6%;
      margin-inline: auto;
      z-index: 9;
      opacity: 0.7;
      mix-blend-mode: hard-light;

      .circle,
      .bar {
        fill: var(--green500);
      }
      .bar {
        transform-origin: 50% 50%;
        transform-box: fill-box;
        animation: barAnimation var(--animation-duration) ease-in-out 0ms infinite alternate none;
      }
      .top {
        fill: var(--white500);
        transform-origin: 50% 50%;
        animation: topAnimation var(--animation-duration) ease-in-out 0ms infinite alternate none;
      }
    }
  }
}

@keyframes topAnimation {
  0%,
  25% {
    fill: var(--green500);
    d: path(
      "M111,183.1c6.3-5.5,13-10.4,20-14.6s14.4-7.9,21.9-10.9,15.4-5.3,23.4-6.8,16.1-2.4,24.3-2.5c8.2,0,16.1.8,23.7,2.2s15,3.5,22.2,6.3,14.2,6.2,21.2,10.3,13.8,8.8,20.6,14.2c0,0,2.4-2.9,6-7.2s8.4-10.1,13.2-15.8,9.7-11.6,13.4-16,6.2-7.5,6.4-7.7c-1.5-1.4-3.1-2.8-4.7-4.1s-3.2-2.7-4.9-4-3.3-2.6-5-3.9-3.3-2.4-5-3.6c-2-1.4-4-2.6-6-3.8s-4-2.2-6.1-3.3-4.1-2-6.2-3-4.2-2-6.3-3.1c-2.2-1.1-4.3-2.1-6.5-3.1s-4.3-1.9-6.5-2.8-4.4-1.8-6.6-2.6-4.4-1.6-6.7-2.3c-2.3-.7-4.6-1.4-6.8-2.1s-4.6-1.2-6.9-1.8-4.6-1.1-6.9-1.5-4.7-.9-7-1.3c-2.4-.4-4.7-.7-7.1-1s-4.8-.5-7.1-.7-4.8-.3-7.2-.4-4.8-.1-7.2-.1-4.8,0-7.2.1-4.8.2-7.2.4-4.8.4-7.1.7-4.7.6-7.1,1c-2.3.4-4.6.8-7,1.3s-4.6,1-6.9,1.5-4.6,1.2-6.9,1.8-4.6,1.3-6.9,2.1c-2.2.7-4.5,1.5-6.7,2.3s-4.4,1.7-6.6,2.6-4.4,1.8-6.5,2.8-4.3,2-6.4,3.1c-2.1,1-4.2,2-6.3,3s-4.1,2-6.2,3.1-4.1,2.1-6.1,3.3-4,2.4-6,3.8c-1.6,1.1-3.3,2.3-4.9,3.6s-3.3,2.5-5,3.9-3.3,2.7-4.9,4-3.2,2.7-4.7,4.1c.2.2,2.5,3.4,6,8s8.1,10.7,12.7,16.7,9.2,12,12.6,16.5,5.7,7.5,5.7,7.5Z"
    );
  }
  75%,
  100% {
    fill: var(--white500);
    d: path(
      "M88.5,120.1c8-5.4,16.4-10.3,25.1-14.5s17.9-7.9,27.3-10.8,19.1-5.2,29.1-6.8,20.1-2.4,30.5-2.5c10.4,0,20.6.9,30.5,2.4s19.7,3.8,29.1,6.8,18.5,6.6,27.3,10.8,17.2,9.1,25.1,14.6c0,0,3-2.9,7.4-7.3s10.4-10.3,16.4-16.2,12-11.9,16.6-16.4,7.7-7.6,8-7.9c-1.9-1.4-3.9-2.7-5.8-4s-4-2.5-6-3.7-4.1-2.4-6.2-3.6-4.2-2.3-6.3-3.4c-2.5-1.4-5.1-2.7-7.7-4s-5.2-2.5-7.8-3.7-5.3-2.4-7.9-3.5-5.3-2.2-8-3.3c-2.7-1.1-5.5-2.1-8.2-3.1s-5.5-1.9-8.3-2.8-5.5-1.7-8.3-2.6-5.6-1.6-8.5-2.3c-2.9-.7-5.8-1.4-8.7-2.1s-5.8-1.2-8.7-1.8-5.8-1.1-8.8-1.5-5.9-.9-8.9-1.3c-3-.4-6-.7-9-1s-6-.5-9-.7-6.1-.3-9.1-.4-6.1-.1-9.1-.1-6.1,0-9.1.1-6.1.2-9.1.4-6,.4-9,.7-6,.6-9,1c-2.9.4-5.9.8-8.8,1.3s-5.9,1-8.8,1.5-5.8,1.1-8.8,1.8-5.8,1.3-8.7,2.1c-2.8.7-5.7,1.5-8.5,2.3s-5.6,1.7-8.4,2.6-5.5,1.8-8.3,2.8-5.5,2-8.1,3.1c-2.7,1-5.4,2.1-8,3.2s-5.3,2.3-7.9,3.5-5.2,2.5-7.8,3.8-5.2,2.6-7.7,4c-2.1,1.1-4.1,2.3-6.2,3.4s-4.1,2.3-6.2,3.6-4.1,2.5-6.1,3.7-4,2.6-5.9,4c.2.2,3.3,3.4,7.9,7.9s10.6,10.5,16.6,16.4,12,11.8,16.4,16.2,7.4,7.3,7.4,7.3Z"
    );
  }
}
@keyframes barAnimation {
  0% {
    rotate: 0deg;
  }
  25% {
    rotate: 0deg;
  }
  75% {
    rotate: -90deg;
  }
  100% {
    rotate: -90deg;
  }
}
