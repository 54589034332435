@use "sass:list";
@use "sass:string";
// ------------------------------
// Mixins
// ------------------------------
// Include fonts
@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff) {
  $src: null;

  @each $ext in $exts {
    $format: $ext;
    $src: list.append(
      $src,
      url(string.quote($fonts-directory + $path + "." + $ext)) format(string.quote($format)),
      comma
    );
  }

  @font-face {
    font-family: string.quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}

// @Media
@mixin media-max($breakpoint) {
  @media (max-width: #{$breakpoint - 0.02px}) {
    @content;
  }
}

@mixin media-min($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-min-max($breakpoint-min,
$breakpoint-max) {
  @media (min-width: $breakpoint-min) and (max-width: #{$breakpoint-max - 0.02px}) {
    @content;
  }
}

// For Internet Explorer
@mixin if-IE() {

  @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    @content;
  }
}

// SVG Color
@mixin svg-color($color) {
  fill: $color;
  color: $color;
}

// Aspect ratio
// https://www.w3schools.com/howto/howto_css_aspect_ratio.asp
@mixin aspect-ratio($percentage) {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: $percentage;
  }

  >* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Reses list styles
@mixin list-clear() {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Reset input styles
@mixin input-clear() {
  outline: none;
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 0;
  background: none;
  appearance: none;
}

// Text ellipsis
// https://www.w3schools.com/csSref/css3_pr_text-overflow.asp
@mixin text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Flex
@mixin f-basis($width) {
  flex-basis: $width;
  max-width: $width;
}

// Position absolute
@mixin absolute-center() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absolute-middle() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-center-middle() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-fullsize() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// =============================================================================
// Hover, Active, Focus ->  @include hover { };
// =============================================================================
@mixin hover {
  &:hover, &:active, &:focus {
    @content;
  }
}
