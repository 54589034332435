// ------------------------------
// Variables
// ------------------------------
:root {
    --section-padding-block: 96px;

    --element-spacer-medium: 64px;
    --element-spacer-large: 160px;

    --elements-gap-small: 15px;
    --elements-gap-medium: 30px;
}

//Font
$font-family: 'Arial', sans-serif;

//Breakpoints
$sm: 640px;
$md: 960px;
$lg: 1200px;
$xl: 1600px;
//Colors
$link: #DC0000;

//Transitions
$t-color: color .3s ease-in-out;
$t-bottom: bottom .3s ease-in-out;
$t-bc: background-color .3s ease-in-out;
$t-bd-b: border-bottom .3s ease-in-out;
$t-bd-col: border-color .3s ease-in-out;
$t-left: left .3s ease-in-out;
$t-margin: margin .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-top: top .3s ease-in-out;
$t-trans: transform .3s ease-in-out;
$t-width: width .3s ease-in-out;
$t-all: all .3s ease-in-out;
$t-all-f: all .1s ease-in-out;

