@use "../helpers/mixins";

.sub_grid {
    --content-max-width: 1440px;
    --gap:50px;
    --gap-960: 25px;
    --gap-640: 10px;
    --column-width: calc( ( 100% - ( 11 * var(--gap) ) ) / 12 ) ;
    --column-width-960: calc( ( 100% - ( 5 * var(--gap-960) ) ) / 6 ) ;
    --column-width-640: calc( ( 100% - ( 1 * var(--gap-640) ) ) / 2 ) ;
  
    overflow-x: hidden;
    display: grid;
    gap: var(--gap);
    align-items: start;
    align-content: start;
    grid-template-columns:
      [c1-start]
          var(--column-width)
      [c1-end c2-start]
          var(--column-width)
      [c2-end c3-start]
          var(--column-width)
      [c3-end c4-start]
          var(--column-width)
      [c4-end c5-start]
          var(--column-width)
      [c5-end c6-start]
          var(--column-width)
      [c6-end c7-start]
          var(--column-width)
      [c7-end c8-start]
          var(--column-width)
      [c8-end c9-start]
          var(--column-width)
      [c9-end c10-start]
          var(--column-width)
      [c10-end c11-start]
          var(--column-width)
      [c11-end c12-start]
          var(--column-width)
      [c12-end];
  
      @include mixins.media-max(960px) {
            gap: var(--gap-960);
            grid-template-columns:
            [c1-start c2-start]
                var(--column-width-960)
            [c1-end c2-end c3-start c4-start]
                var(--column-width-960)
            [c3-end c4-end c5-start c6-start]
                var(--column-width-960)
            [c5-end c6-end c7-start c8-start]
                var(--column-width-960)
            [c7-end c8-end c9-start c10-start]
                var(--column-width-960)
            [c9-end c10-end c11-start c12-start]
                var(--column-width-960)
            [c11-end c12-end]
      }
      @include mixins.media-max(640px) {
            gap: var(--gap-640);
            grid-template-columns:
            [c1-start c2-start c3-start c4-start c5-sart c6-start ]
                var(--column-width-640)
            [c1-end c2-end c3-end c4-end c5-end c6-end c7-start c8-start c9-start c10-start c11-start c12-start ]
                var(--column-width-640)
            [c7-end c8-end c9-end c10-end c11-end c12-end]
      }
  }